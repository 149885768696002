import $ from 'jquery';

import { TimelineMax, TweenMax, TimelineLite, CSSPlugin, ScrollToPlugin, Draggable, AttrPlugin } from "gsap/all"; 

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [ CSSPlugin, AttrPlugin ];


$(document).ready(function() {
  
  /* START: menu */
  const menu = $('#nav-main'),
    menuBtn = $('#nav-button')
  let menuIsOpen = false
  $(menuBtn).click(function () {
    if (menuIsOpen === false) {
      menuOpen();
    } else if (menuIsOpen === true) {
      menuClose();
    }
  });
  function menuOpen(){
    $(menu).addClass('is-open');
    $(menuBtn).addClass('is-active');
    menuIsOpen = true;
  }
  function menuClose(){
    $(menu).removeClass('is-open');
    $(menuBtn).removeClass('is-active');
    menuIsOpen = false;
  }
  /* END: menu */
    
  
  /*var tlKz = new TimelineMax({repeat:-1}),
    kzHeadlineEls = $('#kz-head img'),
    kzHeadlineBackground = $('#kz-head .background')


  for ( var i = 0; i < kzHeadlineEls.length; ++i ) {

      
      tlKz.set(kzHeadlineEls, {
        opacity: 0,
      })
      tlKz.set(kzHeadlineEls[i], {
        opacity: 1
      })
      tlKz.to(kzHeadlineBackground, 0.4, {
        height: 100,
        y: -100
      }, "+=0.1");
      //tlKz.add( function(){ (console.log('icon' + i)) })
      tlKz.set(kzHeadlineBackground, {
        height: 100,
        y: 100
      }, "+=2")
      tlKz.to(kzHeadlineBackground, 0.4, {
        height: 100,
        y: 0
      })

}*/

  var conEl = $("main, #kz-head");

  TweenMax.staggerFromTo(conEl, 1, {
    opacity: .0,
    y: 200
  }, {
    delay:0, 
    opacity: 1,
    y: 0,
    ease: 'Expo.easeOut'
  },0.3)

});